<template>
  <div>
    <!---@visible-change="elCascaderOnlick($event, parent_id)"-->
    <el-cascader v-model="parent_id" :props="cascsderProps" :options="tableData" :disabled="isDisabled" clearable
      :placeholder="placeholderTxt" style="width:100%" @change="change" ref="elcascader"></el-cascader>
  </div>
</template>
<script>
import deptMixins from "../mixins";
export default {
  name: "DeptVue",
  mixins: [deptMixins],
  props: {
    item: {},
    multiple: {
      type: Boolean,
      defalut: false
    }
  },
  data() {
    return {
      tableData: [],
      cascsderProps: { checkStrictly: true, multiple: this.multiple } //checkStrictly: true,
    };
  },
  computed: {
    parent_id: {
      get() {
        return this.item
      },
      set(val) {
        return val;
      }
    }
  },
  mounted() {
    this.getDepartList();
  },
  methods: {
    change(val) {
      if (this.multiple) {
        let parent_id_ = val
          ? JSON.parse(JSON.stringify(val))
          : [];
        this.$emit("setData", parent_id_);
      } else {
        let parent_id_ = val
          ? JSON.parse(JSON.stringify(val))
          : [];
        this.$emit("setData", parent_id_);
      }
      //隐藏下拉框
      this.$refs.elcascader.dropDownVisible = false;
    }
  }
};
</script>
