<template>
  <div class="app-item app-content">
    <div class="top-title flex">
      <h4>员工管理</h4>
      <div class="flex">
        <el-input placeholder="请输入内容" v-model="query" class="input-with-select mr-10">
          <el-button slot="append" icon="el-icon-search" @click="goTo(1)"></el-button>
        </el-input>
        <el-button size="small" type="success" @click="exportUser()">导出员工</el-button>
        <el-button type="primary" size="small" @click="add()" class="el-btn">添加员工</el-button>
        <el-upload action="" :http-request="fnUploadRequest" :show-file-list="false"><el-button size="small"
            type="primary">导入员工</el-button></el-upload>
        <a
          href="https://laobangjiangtang.oss-cn-shanghai.aliyuncs.com/examples/%E5%91%98%E5%B7%A5%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx">模板示例</a>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column v-for="item in tableColumns" :key="item.filed" :prop="item.filed" :label="item.label"
        :fixed="item.fixed" :width="item.width || 'auto'">
      </el-table-column>
      <el-table-column prop="department_id" label="所属部门" width="190">
        <template slot-scope="scope">
          <template v-if="scope.row.parentNameArr && scope.row.parentNameArr.length">
            {{ showParentName(scope.row.parentNameArr, scope.row.depart.parent_id_arr) }}
          </template>
          {{ scope.row.depart && scope.row.depart.name }}
        </template>
      </el-table-column>
      <el-table-column prop="role" label="角色" width="190">
        <template slot-scope="scope">
          {{ scope.row.role ? scope.row.role.name : "" }}
        </template>
      </el-table-column>

      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="updateEmployees(scope.$index, scope.row)">
            <span v-if="showBtn[scope.$index]">保存</span>
            <span v-if="!showBtn[scope.$index]">编辑</span>
          </el-button>
          <!-- <el-button type="text" size="small">停用/启用</el-button> -->
          <el-button type="text" size="small" @click="deleteEmployees(scope.$index, tableData)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Page :total="total" @goTo="goTo"></Page>
    <!-- 添加员工 -->
    <el-dialog :title="title" :visible.sync="dialogFormVisible" class="dialog">
      <el-form label-width="100px" :model="ruleForm" :rules="rules" ref="ruleFormBox" v-if="dialogFormVisible">
        <el-form-item label="员工姓名" prop="nick_name">
          <el-input auto-complete="off" placeholder="请输入员工姓名" v-model="ruleForm.nick_name"></el-input>
        </el-form-item>
        <el-form-item label="员工手机号" prop="mobile">
          <el-input auto-complete="off" placeholder="请输入员工手机号" v-model="ruleForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="员工密码" prop="password">
          <el-input auto-complete="off" placeholder="请输入员工密码" v-model="ruleForm.password"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <div style="text-align: left">
            <el-radio v-model="ruleForm.gender" label="1">男</el-radio>
            <el-radio v-model="ruleForm.gender" label="2">女</el-radio>
          </div>
        </el-form-item>
        <el-form-item label="所属部门" prop="depart_ids">
          <DeptVue @isDisabledFun="isDisabledFuns" ref="deptBox" @setData="setParent" :multiple="false"
            :item="ruleForm.department_ids"></DeptVue>
        </el-form-item>
        <el-form-item label="角色" prop="role_id">
          <RolesVue @isDisabledRolesFun="isDisabledRolesFuns" ref="rolesBox" @setData="setRoles"
            :role_id="ruleForm.role_id" />
        </el-form-item>
        <el-form-item label="职务" prop="title">
          <el-input auto-complete="off" placeholder="请输入员工职务" v-model="ruleForm.title"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :disabled="isDisabledRoles || isDisabled" type="primary" @click="addEmployees()">确 定</el-button>
        <el-button @click="dialogFormVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Page from "../../components/Page.vue";
import DeptVue from "./components/Dept";
import RolesVue from "./components/Roles";
let defaultForm = {
  id: "",
  title: "",
  mobile: "",
  gender: "1",
  role_id: "",
  password: "",
  nick_name: "",
  department_ids: [],
};
export default {
  components: {
    Page,
    DeptVue,
    RolesVue,
  },
  data() {
    return {
      isDisabled: false,
      isDisabledRoles: false,
      userId: null,
      pwdShow: false,
      dialogFormVisible: false,
      deptname: "",
      deptmobile: "",
      deptpassword: "",
      deptgender: "2",
      depttitle: "",
      dept: [],
      deptduties: "",
      manager: "",
      tableData: [],
      // depts: [],
      showEdit: [], // 显示编辑框
      showBtn: [], // 控制按钮 编辑/保存
      total: 0,
      page: 1, // 当前页面
      lastPage: 1, // 最后一页
      query: "",
      deptnames: [],
      role_id: "",
      gender: "",
      loading: false,
      rolesList: [],
      title: "",
      editId: "",
      tableColumns: [
        { label: "员工姓名", filed: "nick_name", width: 120, fixed: true },
        { label: "员工手机号", filed: "mobile", width: 150 },
        // { label: "员工密码", filed: "password", width: 100 },
        { label: "性别", filed: "gender", width: 100 },
        { label: "职务", filed: "title" }
      ],
      ruleForm: {
        id: "",
        title: "",
        mobile: "",
        gender: "1",
        user_id: "",
        nick_name: "",
        password: "",
        role_id: "",
        department_ids: [],
      },
      rules: {
        nick_name: [
          { required: true, message: "请输入员工姓名", trigger: "blur" },
        ],
        // "title": [
        //   { required: true, message: "请输入员工职务", trigger: "blur" },
        // ],
        // "password": [
        //   { required: true, message: "请输入密码", trigger: "blur" },
        // ],
        // "role_id": [
        //   { required: true, message: "请选择角色", trigger: "change" },
        // ],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { pattern: /^[1][3-9][0-9]{9}$/, message: "请填写正确的手机号码" },
        ],
      },
    };
  },
  methods: {
    isDisabledRolesFuns(e) {
      this.isDisabledRoles = e;
    },
    isDisabledFuns(e) {
      this.isDisabled = e;
    },
    add() {
      this.title = "添加员工";
      this.ruleForm = { ...defaultForm };
      this.dialogFormVisible = true;
    },
    setRoles(data) {
      //rolesList
      this.ruleForm.role_id = data;
    },
    setParent(data) {
      this.ruleForm.department_ids = data;
    },
    // 获取员工列表
    async getEmployees(url) {
      this.loading = true;
      this.pwdShow = false;
      this.$store.dispatch("getEmployees", url).then((res) => {
        //仅支持单选
        let tableData = JSON.parse(JSON.stringify(this.dept));
        //仅支持单选
        this.tableData = res.data.map(list => {
          //上级部门id集合
          let parent_id_arr = list.depart && list.depart.parent_id_arr;
          //有上级部门Id
          if (parent_id_arr && parent_id_arr.length > 0) {
            list.parentNameArr = tableData.filter(item => item.id == parent_id_arr[0]);
          }
          return { ...list }
        });
        this.total = res.total;
        // 处理页面
        this.page = res.current_page;
        // 最后一页
        this.lastPage = res.last_page;
        this.loading = false;
      });
    },
    /***
     * 显示上级部门数据
     */
    showParentName(arr, ids) {
      let str = '';
      let arr_ = this.treeToArray(arr);
      arr_.forEach(list => {
        ids.forEach(id => {
          if (id == list.id) {
            str += list.name + ' - '
          }
        })
      })
      return str;
    },
    /***
     * js将树形结构转换一维数组
     */
    treeToArray(tree) {
      let arr = [];
      const expanded = datas => {
        if (datas && datas.length > 0) {
          datas.forEach(e => {
            arr.push(e);
            expanded(e.childs);
          })
        }
      };
      expanded(tree);
      return arr;
    },
    // 翻页
    goTo(page) {
      this.getEmployees(`/api/web/store/users?page=${page}&q=${this.query || ''}`);
    },
    // 增加员工信息
    addEmployees() {
      this.$refs["ruleFormBox"].validate(async (valid) => {
        if (valid) {
          let res, obj = {};
          let { id } = this.ruleForm;
          for (let key in this.ruleForm) {
            if (this.ruleForm[key])
              obj[key] = this.ruleForm[key]
          }
          // let department_idsAtt = [];
          // obj.department_ids.forEach((o) => {
          //   if (o.length) {
          //     department_idsAtt.push(o[o.length - 1]);
          //   } else {
          //     department_idsAtt.push(o);
          //   }
          // });
          //单选走着
          obj.department_ids = [
            obj.department_ids[obj.department_ids.length - 1],
          ];

          //部门数据
          // let departmentIds = obj.department_ids && obj.department_ids.flat();
          // let arr = Array.from(new Set(departmentIds));
          // obj.department_ids = arr;


          if (id) {
            delete obj.depart_ids;
            if (!obj.password) {
              delete obj.password;
            }
            res = await this.$store.dispatch("updateEmployees", obj);
          } else {
            delete obj.id;
            res = await this.$store.dispatch("addEmployees", obj);
          }
          if (res) {
            this.$message({
              message: id ? "编辑成功" : "添加成功",
              type: "success",
            });
            this.getEmployees();
            this.dialogFormVisible = false;
          }
        }
      });
    },
    // 删除员工信息
    deleteEmployees(index, params) {
      if (this.userId === params[index].id) {
        this.$message({
          message: "管理员账户禁止删除",
          type: "error",
        });
        return false;
      } else {
        let array = params.splice(index, 1);
        let id = array[0].user_id;
        this.$store.dispatch("deleteEmployees", id).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
          }
        });
      }
    },
    // 修改员工信息
    updateEmployees(index, row) {
      this.title = "编辑员工";
      let gender = row.gender === "女" ? "2" : "1";
      for (let key in this.ruleForm) {
        this.ruleForm[key] = row[key];
      }
      this.ruleForm.gender = gender;
      this.ruleForm.department_ids = row.depart_ids;
      this.dialogFormVisible = true;
    },
    // 上传员工信息文件
    fnUploadRequest(option) {
      this.loading = true;
      let file = option.file;
      let formData = new FormData();
      formData.append("file", file);
      this.$store.dispatch("importUser", formData).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.loading = false;
        }
      });
    },
    // 导出员工信息文件
    exportUser() {
      // 返回数据的格式指定为blob
      this.$store
        .dispatch("exportUser", { responseType: "blob" })
        .then((res) => {
          // 创建一个新的 URL 对象
          let url = window.URL.createObjectURL(res);
          // 在页面上生成一个a标签并指定href为上面的url,然后模拟点击，以实现自动下载
          var link = document.createElement("a");
          document.body.appendChild(link);
          link.href = url;
          link.download = "员工信息表" + ".xlsx";
          link.click();
        });
    },
  },
  async mounted() {
    await this.$store.dispatch("getDepartments").then((res) => {
      this.dept = res;
      this.$store.dispatch("getInfo").then((res) => {
        this.userId = res.info.id;
      });
    });
    await this.getEmployees();
  },

};
</script>
<style lang="less" scoped>
.app-item {
  height: 100%;

  .top-title {
    // display: flex;
    justify-content: space-between;

    // align-items: center;
    .el-button {
      padding: 0 10px;
      height: 40px;
    }

    .el-btn {
      margin: 0 10px;
    }

    a {
      font-size: 12px;
      margin-top: 22px;
      margin-left: 2px;
      color: #409eff;
    }
  }

  .dialog {
    text-align: center;

    .dialog-footer {
      text-align: center;
      margin-top: -10px;

      .el-button {
        padding: 12px 30px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .el-table-name {
    width: 80px !important;
  }

  .top-title {
    h4 {
      height: auto;
      line-height: normal;
    }
  }
}

.mr-10 {
  margin-right: 10px
}
</style>
